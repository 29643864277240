
import React, { Suspense, useEffect, useState, Fragment, useRef } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Dialog, Menu, Transition } from '@headlessui/react'
import { useFormik } from "formik";
import { accountSchema } from "../../../schemas";
import empty from "../../../assets/images/empty.png"
import moment from "moment";
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../helper/commonHelper";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
  ArrowDownIcon,
  ArrowUpIcon
} from '@heroicons/react/20/solid'
import {
  BellIcon, XMarkIcon,
  TvIcon,
  UsersIcon,
  UserMinusIcon,
  CircleStackIcon,
  ComputerDesktopIcon,
  ArrowTrendingUpIcon,

} from '@heroicons/react/24/outline'
import WhopServices from "../../../ApiServices/WhopServices";
import { toast } from "react-toast";
import makeAnimated from 'react-select/animated';
import Select from "react-select";
import { FaSpinner } from "react-icons/fa";
import FSLoader from "../../../components/FSLoader";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Settings from "./Settings"
import ConnectedAccLoader from "../../../components/ConnectedAccLoader";
import SelectField from "../../../components/selectFieldsFilter/SelectField"

const secondaryNavigation = [
  { name: 'Last 1 Hours', value: 60, href: '#', current: false },
  { name: 'Last 1 days', value: 24, href: '#', current: false },
  { name: 'Last 30 days', value: 30, href: '#', current: false },
  { name: 'All-time', value: 0, href: '#', current: true },
]

const last60 = [
  {
    id: 1,
    name: 'Salman',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/3899/3899618.png',
    lastInvoice: { date: 'December 13, 2022', dateTime: '2022-12-13', amount: '₹2,000.00', status: 'Overdue' },
  },
  {
    id: 2,
    name: 'Vijay',
    imageUrl: 'https://cdn-icons-png.flaticon.com/512/3899/3899618.png',
    lastInvoice: { date: 'January 22, 2023', dateTime: '2023-01-22', amount: '₹14,000.00', status: 'Paid' },
  }
]


const connections = [{ value: 60, label: "Last 60 Minutes" }, { value: 24, label: "Last 24 Hours" }, { value: 30, label: "Last 30 Days" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
// Example usage:
const fields = [
  {
    label: "Account No.",
    value: "mua_acc_login_str"
  }, {
    label: "Balance",
    value: "mua_acc_balance"
  }, {
    label: "Today's PNL",
    value: "mua_acc_real_pnl"
  }, {
    label: "PNL",
    value: "mua_acc_unreal_pnl"
  }, {
    label: "Broker",
    value: "mua_acc_server"
  }, {
    label: "Open Positions",
    value: "mua_acc_positions"
  }, {
    label: "Risk Level",
    value: "mua_acc_risksettings"
  }, {
    label: "Last Active",
    value: "mua_timestamp"
  }, {
    label: "User Name",
    value: "user_name"
  },
  {
    label: "User Email",
    value: "user_email"
  },
  {
    label: "Drawdown %",
    value: "current_dd"
  },
  {
    label: "Margin Level",
    value: "mua_acc_margin_level"
  },
  {
    label: "Cummulative Lotsize",
    value: "cumulative_sum"
  }


];

const FiltersOperarorField = [
  { label: "==", value: "equals" },
  { label: "!=", value: "not" },
  { label: ">", value: "gt" },
  { label: "<", value: "lt" },
  { label: ">=", value: "gte" },
  { label: "<=", value: "lte" },
  { label: "IN", value: "in" },
  { label: "NOT IN", value: "notIn" },
  { label: "LIKE", value: "startsWith" }, // Adjust as needed
  { label: "CONTAINS", value: "contains" },
  // { label: "REGEX", value: "matches" }, // Requires raw SQL
  // { label: "IS NULL", value: "isNull", logic: (field) => ({ [field]: null }) }, // Handled separately
  // { label: "IS NOT NULL", value: "isNotNull", logic: (field) => ({ NOT: { [field]: null } }) }, // Handled separately
  // { label: "ALL", value: "not" }, // Adjust use case
  // { label: "ANY", value: "equals" }
];


const Home = () => {
  let hasActiveTrades = false;
  const timeoutIdRef = useRef(null);
  const debounceTimeoutRef = useRef(null);
  const latestValues = useRef({ selectedTime: null, accountNo: null, fieldsOrder: null, fieldsValue: null, fieldsOperator: null });

  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [type, setType] = useState('Add');
  const [secNavigation, setSecNavigation] = useState(secondaryNavigation);
  const [accountNo, setAccountNo] = useState(null);
  const [fieldsOrder, setFieldsOrder] = useState(false);
  const [fieldsValue, setfieldsValue] = useState(false);
  const [fieldsOperator, setFieldsOperator] = useState(false);
  const [filtersOperarorField, setFilteredOperators] = useState(FiltersOperarorField);

  const [selectedLicId, setSelectedLicId] = useState({ value: '', label: '' });
  const [selectedUserId, setSelectedUserId] = useState({ value: '', label: '' });
  const [selectedProductId, setSelectedProductId] = useState({ value: '', label: '' });
  const [selectedPreAccountId, setSelectedPreAccountId] = useState({ value: '', label: '' });
  const [licenseList, setLicenseList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [preAccountList, setPreAccountList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isFSLoading, setSFLoading] = useState(false);
  const [lam_id, setAccountid] = useState("");
  const [setting, setSetting] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [dashCounts, setDashCounts] = useState([]);
  const [selectedTime, setSelectedTime] = useState(connections[0].value);
  const [selectedTimeLabel, setSelectedTimeLabel] = useState(connections[0].label);
  const [currentConnection, setCurrentConnection] = useState({ title: "Last 60 Minutes", data: last60 });
  const [isConectionLoading, setIsConectionLoading] = useState(false);

  const animatedComponents = makeAnimated();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  let initialValues = {
    lam_id: "",
    fk_wp_lic_id: "",
    fk_wp_user_id: "",
    fk_wp_prod_id: "",
    fk_previous_account_id: "",
    user_name: "",
    prod_name: "",
    lam_account_no: "",
    lam_is_banned: false,
    lam_is_active: true,
  };
  const handleFieldChange = (selectedValue) => {
    selectedValue.preventDefault();
    setIsConectionLoading(true);
    console.log("Selected Field:", selectedValue.target.value);
    setfieldsValue(selectedValue.target.value);
    const stringFields = [
      "mua_acc_login_str",
      "mua_acc_server",
      "mua_acc_risksettings",
      "user_email",
      "user_name"
    ];
    const numericFields = [
      "mua_acc_balance",
      "mua_acc_real_pnl",
      "mua_acc_unreal_pnl",
      "mua_acc_positions",
      "mua_acc_margin_level",
      "current_dd",
      "cumulative_sum"
    ];

    const dateFields = [
      "mua_timestamp",
    ];
    setAccountNo(null)

    if (numericFields.includes(selectedValue.target.value)) {
      setFilteredOperators(FiltersOperarorField.filter(op => !["startsWith", "contains"].includes(op.value)));
    } else if (stringFields.includes(selectedValue.target.value)) {
      console.log("String field selected", FiltersOperarorField.filter(op => !["gt", "lt", "gte", "lte"].includes(op.value)));
      setFilteredOperators(FiltersOperarorField.filter(op => !["gt", "lt", "gte", "lte"].includes(op.value)));
    } else if (dateFields.includes(selectedValue.target.value)) {
      setFilteredOperators(FiltersOperarorField.filter(op => !["startsWith", "contains", "notIn", "in", "not"].includes(op.value)));
    } else {
      setFilteredOperators(FiltersOperarorField);
    }
    getAllAccounts(selectedTime);
  };
  const handleChangeOrder = (selectedValue) => {

    setIsConectionLoading(true);
    // console.log("Selected Order:", selectedValue);
    setFieldsOrder(selectedValue);
    getAllAccounts(selectedTime);
  };
  const handleChangeOperator = (selectedValue) => {

    setIsConectionLoading(true);
    console.log("Selected field Operator Order:", selectedValue.target.value);
    // setFieldsOperator(selectedValue.target.value);
    getAllAccounts(selectedTime);
  };

  const handleChangeAccountNo = (selectedValue) => {

    setIsConectionLoading(true);
    // console.log("Selected setAccountNo:", selectedValue);
    setAccountNo(selectedValue);
    getAllAccounts(selectedTime);
  };
  const handleConnectChange = (e) => {

    setIsConectionLoading(true);
    const selectedValue = e.target.value;
    const options = Array.from(e.target.options);
    const selectedOption = options.find(option => option.value === selectedValue);
    const label = selectedOption ? selectedOption.textContent : '';

    setSelectedTime(selectedValue);
    setSelectedTimeLabel(label);
    hasActiveTrades = false;

    setIsConectionLoading(true);
    getAllAccounts(selectedValue);
  };
  const getAllSettings = async (payload) => {
    try {
      const res = await WhopServices.getAllWhopSettings(payload)
      if (res.status) {
        setSetting(res?.data?.data?.length > 0 ? res?.data?.data[0] : false);
      }
    } catch (e) {
    }
  }
  const onSettingStatusChange = async (setting_id, item) => {

    let { data, status } = await WhopServices.SaveWhopSettings({ setting_id, ...item })
    if (status === 200) {
      if (data.status) {
        setSetting(data?.data);
        toast.success(data.message);
      }
    } else {
      toast.error(data.message);
    }
  };
  const getAllAccounts = async (value) => {
    if (window.location.pathname === "/") {
      let payload = `1&time=${value}`;
      // // console.log("accountNo=>", accountNo)
      // console.log("fieldsOrder=>", fieldsOrder)
      // console.log("fieldsValue=>", fieldsValue)
      console.log("fieldsOperator=>", fieldsOperator)
      if (accountNo) {
        payload += `&accountNo=${accountNo}`;
      }
      if (fieldsOrder) {
        payload += `&fieldsOrder=${fieldsOrder}`;
      }
      if (fieldsValue) {
        payload += `&fieldsValue=${fieldsValue}`;
      }
      if (fieldsOperator) {
        payload += `&fieldsOperator=${fieldsOperator}`;
      }
      try {
        const res = await WhopServices.getAllWhopConnectedAccount(payload)
        if (res.status) {
          setAccountList(res.data.data);
        }
        setIsConectionLoading(false);
        return true; // Indicate success
      } catch (e) {
        setIsConectionLoading(false);
        return false;
      }
    }
  }
  const getAllDashAccounts = async (value) => {
    setIsLoading(true);
    secNavigation.map((item) => {
      item.current = false;
      if (item.value == value) {
        item.current = true;
      }
    })
    setSecNavigation(secNavigation)
    let payload = `1&time=${value}`;
    try {
      const res = await WhopServices.getAllWhopDashCount(payload)
      if (res.status) {
        let stats = [];
        if (res?.data?.data) {
          let total = res?.data?.data;
          if (total?.totalWhopLicensesCount) {
            stats.push({ name: 'Licenses', active: total?.totalWhopActiveLicensesCount, inactive: total?.totalWhopInActiveLicensesCount, total: total?.totalWhopLicensesCount, change: TvIcon, changeType: 'positive' })
          }
          if (total?.totalWhopUsersCount) {
            stats.push({ name: 'Customers', active: total?.totalWhopActiveUsersCount, inactive: total?.totalWhopInActiveUsersCount, total: total?.totalWhopUsersCount, change: UsersIcon, changeType: 'positive' })
          }
          if (total?.totalWhopProductsCount) {
            stats.push({ name: 'Products', active: total?.totalWhopActiveProductsCount, inactive: total?.totalWhopInActiveProductsCount, total: total?.totalWhopProductsCount, change: CircleStackIcon, changeType: 'positive' })
          }
          if (total?.totalWhopAccountsCount) {
            stats.push({ name: 'Accounts', active: total?.totalWhopActiveAccountsCount, inactive: total?.totalWhopInActiveAccountsCount, total: total?.totalWhopAccountsCount, change: UserMinusIcon, changeType: 'positive' })
          }

        }

        setDashCounts(stats);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  const [formAccount, setFormAccount] = useState(initialValues);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect('/account', permissions, navigate);
      const actions = getAllowedActions(permissions, '/account');
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    getAllUser(1);
    if (localStorage.getItem("role_id") !== "4") {
      getAllAccounts(selectedTime);
    }
    getAllDashAccounts(0);
    getAllSettings(1)
  }, []);

  // useEffect(() => {
  //   latestValues.current = { selectedTime, accountNo, fieldsOrder, fieldsValue, fieldsOperator };

  //   if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current);

  //   debounceTimeoutRef.current = setTimeout(() => {
  //     fetchAccounts();
  //   }, 5000); // Reduced delay for better responsiveness

  //   return () => {
  //     if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current);
  //     if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
  //     setIsConectionLoading(false);
  //   };
  // }, [selectedTime, accountNo, fieldsOrder, fieldsValue, fieldsOperator]);

  // const fetchAccounts = async () => {
  //   const { selectedTime, accountNo, fieldsOrder, fieldsValue, fieldsOperator } = latestValues.current;

  //   if (
  //     (selectedTime && accountNo && fieldsValue && fieldsOperator && fieldsOrder) ||
  //     (selectedTime && accountNo && !(fieldsValue || fieldsOperator || fieldsOrder)) ||
  //     (selectedTime && fieldsOrder && fieldsValue && !accountNo && !fieldsOperator) ||
  //     (selectedTime && accountNo && fieldsValue && fieldsOperator && !fieldsOrder) ||
  //     (accountNo && fieldsValue && fieldsOperator && !selectedTime && !fieldsOrder) ||
  //     (fieldsOrder && fieldsValue && !selectedTime && !fieldsOperator && !accountNo) ||
  //     (selectedTime && !(accountNo || fieldsOrder || fieldsValue || fieldsOperator))
  //   ) {
  //     try {
  //       if (localStorage.getItem("role_id") !== "4") {
  //         const success = await getAllAccounts(selectedTime);
  //         if (success) {
  //           timeoutIdRef.current = setTimeout(fetchAccounts, 5000);
  //         }
  //       }
  //     } catch (e) {
  //       console.error("Error in recursive call:", e);
  //     }
  //   }
  // };
  
  useEffect(() => {
    latestValues.current = { selectedTime, accountNo, fieldsOrder, fieldsValue, fieldsOperator };

    if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      fetchAccounts();
    }, 500); // Adjust debounce timing

    return () => {
      if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current);
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      setIsConectionLoading(false);
    };
  }, [selectedTime, accountNo, fieldsOrder, fieldsValue, fieldsOperator]);

  const fetchAccounts = async () => {
    // Cancel any previously scheduled calls before making a new one
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);

    const { selectedTime, accountNo, fieldsOrder, fieldsValue, fieldsOperator } = latestValues.current;

    if (
      (selectedTime && accountNo && fieldsValue && fieldsOperator && fieldsOrder) ||
      (selectedTime && accountNo && !(fieldsValue || fieldsOperator || fieldsOrder)) ||
      (selectedTime && fieldsOrder && fieldsValue && !accountNo && !fieldsOperator) ||
      (selectedTime && accountNo && fieldsValue && fieldsOperator && !fieldsOrder) ||
      (accountNo && fieldsValue && fieldsOperator && !selectedTime && !fieldsOrder) ||
      (fieldsOrder && fieldsValue && !selectedTime && !fieldsOperator && !accountNo) ||
      (selectedTime && !(accountNo || fieldsOrder || fieldsValue || fieldsOperator))
    ) {
      try {
        if (localStorage.getItem("role_id") !== "4") {
          console.log("Calling API with:", latestValues.current);
          const success = await getAllAccounts(selectedTime);
          if (success) {
            // Schedule the next fetch with the latest values
            timeoutIdRef.current = setTimeout(fetchAccounts, 5000);
          }
        }
      } catch (e) {
        console.error("Error in recursive call:", e);
      }
    }
  };

  const getAllUser = async (payload) => {
    try {
      const res = await WhopServices.getAllWhopUser(payload)
      if (res.status) {
        setUserList(res.data.data);
      }
    } catch (e) {
    }
  }

  const getAllLicense = async (value) => {
    let payload = `1&fk_wp_user_id=${value}&fk_wp_prod_id=${selectedProductId?.value}`;
    try {
      setSFLoading(true);
      const res = await WhopServices.getAllWhopLicense(payload)
      if (res.status) {
        setLicenseList(res.data.data);
      }
      setSFLoading(false);
    } catch (e) {
      setSFLoading(false);
    }
  }
  const getAllProduct = async (value) => {
    let payload = `1&fk_wp_user_id=${value}`;
    try {
      setSFLoading(true);
      const res = await WhopServices.getAllProductByUser(payload);
      if (res.status) {
        setProductList(res.data.data);
      }
      setSFLoading(false);
    } catch (e) {
      setSFLoading(false);
    }
  }
  const getAllPreviousAccount = async (value) => {
    let payload = `1&fk_wp_user_id=${value}`;
    try {
      setSFLoading(true);
      const res = await WhopServices.getAllWhopAccount(payload)
      if (res.status) {
        setPreAccountList(res.data.data);
      }
      setSFLoading(false);
    } catch (e) {
      setSFLoading(false);
    }
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formAccount,
      validationSchema: accountSchema,
      onSubmit: async (values, action) => {

        let body = {
          lam_id: type == "Edit" ? values.fk_previous_account_id : lam_id,
          fk_wp_lic_id: values.fk_wp_lic_id,
          fk_wp_user_id: values.fk_wp_user_id,
          fk_wp_prod_id: values.fk_wp_prod_id,
          fk_previous_account_id: values.fk_previous_account_id,
          lam_account_no: values.lam_account_no,
          lam_is_banned: values.lam_is_banned,
          lam_is_active: true,
          licAccountArr: [],
        };

        setDisableSubmitButton(true);

        if (body.lam_account_no != undefined || body.lam_account_no != null || body.lam_account_no != "") {

          let { data, status, message } = await WhopServices.SaveWhopAccount(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)
            } else {
              toast.error(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 5000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            toast.error(data.message);
            setDisableSubmitButton(false);
          }
        } else {

          action.resetForm();
          if (modalOpenFlage === true) {
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });

  const handleDrawer = (type, id, obj) => {
    setSelectedLicId('');
    setSelectedPreAccountId('')
    setSelectedProductId('')
    setSelectedUserId('')
    values.fk_wp_lic_id = "";
    values.fk_wp_user_id = "";
    values.fk_wp_prod_id = "";
    values.fk_previous_account_id = "";

    setAccountid("");
    setFormAccount(initialValues);

    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  return (
    <>
      {isLoading ? (
        <FallingLinesLoader />
      ) : (<main>

        <FSLoader isLoading={isFSLoading} title="Processing..." />

        <div className="relative isolate overflow-hidden bg-white border rounded-md">

          <header className="pb-4 pt-6 sm:pb-6">
            <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <h1 className="text-base font-semibold leading-7 text-gray-900">Statistics</h1>
              <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">

                <a className='text-[#0172fd]'>
                  All Time
                </a>
              </div>

              <div className="ml-auto flex items-center gap-x-4">
                {(allowedActions.filter((data) => data.permission_id == 52)?.length >
                  0 || localStorage.getItem("role_id") == 4) ? (<Link
                    onClick={() => { handleDrawer("add", "", {}); setType("add") }}
                    type="button"
                    className="ml-auto flex items-center gap-x-1 rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#01adfd] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
                    Add Account
                  </Link>
                ) : null}
                {(allowedActions.filter((data) => data.permission_id == 52)?.length >
                  0 || localStorage.getItem("role_id") == 4) ? (<Link
                    onClick={() => { handleDrawer("Edit", "", {}); setType("Edit"); }}
                    type="button"
                    className="ml-auto flex items-center gap-x-1 rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#01adfd] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Update Account
                  </Link>
                ) : null}

                {localStorage.getItem("role_id") !== "4" ? (<Settings data={setting} onStatusChange={onSettingStatusChange} />) : null}

              </div>
            </div>
          </header>

          {/* Stats */}
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              {dashCounts?.length > 0 && dashCounts?.map((stat, statIdx) => (
                <div
                  key={stat.name}
                  className={classNames(
                    statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                    'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8'
                  )}
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                  <dd
                    className={classNames(
                      stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',
                      'text-xs font-medium'
                    )}
                  >
                    <stat.change
                      className="mr-3 h-10 w-10"
                      aria-hidden="true"
                    />

                  </dd>
                  <dd className="w-full flex-none text-4xl font-medium leading-10 tracking-tight text-gray-900">
                    {stat.total}
                  </dd>

                </div>
              ))}
            </dl>
          </div>

          <div
            className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
            aria-hidden="true"
          >
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
              style={{
                clipPath:
                  'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
              }}
            />
          </div>
        </div>

        {localStorage.getItem("role_id") !== "4" ? (<div className="space-y-16 py-6 my-5 xl:space-y-5 border rounded-md bg-white">
          {/* Recent activity table */}
          <div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex align-middle items-center gap-2">
                <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none mr-2">
                  Connected Accounts
                </h2>
                <select className="rounded-md text-xs" onChange={(e) => {
                  e.preventDefault();
                  handleConnectChange(e);
                }}>
                  {connections.map((data, index) => (
                    <option key={index} value={data.value}>{data.label}</option>
                  ))}
                </select>
                {/* <SelectField fields={fields} onChange={handleFieldChange} /> */}
                <select className="rounded-md text-sm" value={fieldsValue} onChange={handleFieldChange}>
                  <option value="">Select Field</option>
                  {fields?.map(field => (
                    <option key={field.value} value={field.value}>{field.label.replace(/^mua_/, '').replace(/_/g, ' ').toUpperCase()}</option>
                  ))}
                </select>
                <select className="rounded-md text-xs" onChange={(e) => {
                  e.preventDefault();
                  setFieldsOperator(e.target.value);
                  handleChangeOperator(e);
                }}>
                  <option value="">Select Filter</option>
                  {filtersOperarorField.map((data, index) => (
                    <option key={index} value={data.value}>{data.label}</option>
                  ))}
                </select>

                <select className="rounded-md text-xs" onChange={(e) => {
                  e.preventDefault();
                  setFieldsOrder(e.target.value);
                  handleChangeOrder(e.target.value);
                }}>
                  <option value="">Select Order</option>
                  <option value={'asc'}>ASC</option>
                  <option value={'desc'}>DESC</option>

                </select>

                <input
                  value={accountNo}
                  type={fieldsValue === 'mua_timestamp' ? "date" : "text"}
                  placeholder="Search Account No"
                  name="accountNo"
                  autoComplete="off"
                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  onChange={(e) => { e.preventDefault(); handleChangeAccountNo(e.target.value) }}
                />
              </div>

            </div>
            <div className="mt-6 overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">


                </div>
              </div>
            </div>
          </div>

          {/* Last 60 minutes data */}
          {!isConectionLoading ? (

            <div className="mx-auto max-w-7xl px-4 sm:px-4 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <div className="flex items-center justify-between">
                  <h2 className="text-base font-semibold leading-7 text-[#0172fd]">{selectedTimeLabel}</h2>
                </div>

                {accountList.length > 0 ? (
                  <>
                    <Suspense fallback={<ConnectedAccLoader items={3} />}>
                      <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">

                        {(accountList.length > 0 && accountList?.map((client, index) => {
                          return (

                            <li key={client.lam_id} className="overflow-hidden rounded-xl border border-gray-200 cursor-pointer"
                              onClick={(e) => {
                                navigate("/accm?uid=" + client?.license_account_map_relation?.master_whop_user_relation.wp_user_id + "&name=" + client?.license_account_map_relation?.master_whop_user_relation.user_name + "&accid=" + client.mua_acc_login);
                              }}
                            >
                              <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                <img
                                  src={`profile.png`}
                                  alt={client?.license_account_map_relation?.master_whop_user_relation?.user_name}
                                  className="h-12 w-12 flex-none rounded-full bg-white object-cover ring-1 ring-gray-900/10"
                                />
                                <div className="text-sm font-medium leading-6 text-gray-900">{client?.license_account_map_relation?.master_whop_user_relation?.user_name}</div>
                                <div as="div" className="relative ml-auto">
                                  <div className={`${(selectedTime == 60) ? 'text-green-700 bg-green-50 ring-green-600/20' : (selectedTime == 24) ? 'text-orange-700 bg-orange-50 ring-orange-600/20' : 'text-gray-700 bg-gray-50 ring-gray-600/20'} rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset`} >
                                    {(selectedTime == 60) ? 'Active' : (selectedTime == 24) ? 'Stoped' : "In-Active"}
                                  </div>
                                </div>
                              </div>
                              <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Account No.</dt>
                                  <dd className="text-gray-700">
                                    {client?.mua_acc_login}
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Balance</dt>
                                  <dd className="text-gray-700">
                                    {client?.mua_acc_balance ? client?.mua_acc_balance : "N/A"} $
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Today's PNL</dt>
                                  <dd className={classNames(
                                    (parseInt(client?.mua_acc_real_pnl) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.mua_acc_real_pnl) < 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                    'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                  )}>
                                    {(parseInt(client?.mua_acc_real_pnl) > 0) ? (
                                      <ArrowUpIcon
                                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ArrowDownIcon
                                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                    {client?.mua_acc_real_pnl ? client?.mua_acc_real_pnl : "N/A"}
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Total PNL</dt>
                                  <dd
                                    className={classNames(
                                      (parseInt(client?.total_pnl) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.total_pnl) < 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                      'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                    )}
                                  >
                                    {(parseInt(client?.total_pnl) > 0) ? (
                                      <ArrowUpIcon
                                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ArrowDownIcon
                                        className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                    {client?.total_pnl ? client?.total_pnl : "N/A"}
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">PNL</dt>
                                  <dd className="flex items-start gap-x-2">
                                    <div
                                      className={classNames(
                                        (parseInt(client?.mua_acc_unreal_pnl) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.mua_acc_unreal_pnl) < 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                        'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                      )}
                                    >
                                      {(parseInt(client?.mua_acc_unreal_pnl) > 0) ? (
                                        <ArrowUpIcon
                                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <ArrowDownIcon
                                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                          aria-hidden="true"
                                        />
                                      )}

                                      <span className="sr-only"> {(parseInt(client?.mua_acc_unreal_pnl) > 0) ? 'Increased' : 'Decreased'} by </span>
                                      {client?.mua_acc_unreal_pnl}
                                    </div>
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Margin Level</dt>
                                  <dd className="flex items-start gap-x-2">
                                    <div className={classNames(
                                      (parseInt(client?.mua_acc_margin_level) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.mua_acc_margin_level) <= 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                      'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                    )}>
                                      {(parseInt(client?.mua_acc_margin_level) > 0) ? (
                                        <ArrowUpIcon
                                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <ArrowDownIcon
                                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                          aria-hidden="true"
                                        />
                                      )}
                                      {client?.mua_acc_margin_level ? client?.mua_acc_margin_level : 'N/A'}
                                    </div>
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Cummulative Lotsize</dt>
                                  <dd className="flex items-start gap-x-2">
                                    <div className={classNames(
                                      (parseInt(client?.cumulative_sum) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.cumulative_sum) <= 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                      'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                    )}>
                                      {(parseInt(client?.cumulative_sum) > 0) ? (
                                        <ArrowUpIcon
                                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <ArrowDownIcon
                                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                          aria-hidden="true"
                                        />
                                      )}
                                      {client?.cumulative_sum ? client?.cumulative_sum : 'N/A'}
                                    </div>
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Broker</dt>
                                  <dd className="flex items-start gap-x-2">
                                    <div className="font-medium text-gray-500">
                                      {client?.mua_acc_server ? client?.mua_acc_server : 'N/A'}
                                    </div>
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Drawdown %</dt>
                                  <dd className="flex items-start gap-x-2">
                                    <div className={classNames(
                                      (parseInt(client?.current_dd) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.current_dd) <= 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                      'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                    )}>
                                      {(parseInt(client?.current_dd) > 0) ? (
                                        <ArrowUpIcon
                                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <ArrowDownIcon
                                          className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                          aria-hidden="true"
                                        />
                                      )}
                                      {(client?.current_dd || client?.current_dd == 0) ? client?.current_dd + '%' : 'N/A'}
                                    </div>
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Open Positions</dt>
                                  <dd className="flex items-start gap-x-2">
                                    <div className="font-medium text-gray-500">
                                      {client?.mua_acc_positions ? client?.mua_acc_positions : 'N/A'}
                                    </div>
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Risk Level</dt>
                                  <dd className="flex items-start gap-x-2">
                                    <div className={classNames(
                                      (client?.mua_acc_risksettings == 'High Risk') ? 'bg-red-100 text-red-800' : (client?.mua_acc_risksettings == 'Low Risk') ? 'bg-green-100 text-green-800' : (client?.mua_acc_risksettings == 'Medium Risk') ? 'bg-orange-100 text-orange-800' : 'bg-gray-100 text-gray-600',
                                      'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                    )}>
                                      {client?.mua_acc_risksettings ? client?.mua_acc_risksettings : 'N/A'}
                                    </div>
                                  </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="text-gray-500">Last Active</dt>
                                  <dd className="flex items-start gap-x-2">
                                    <div className="font-medium text-gray-500">
                                      {client?.mua_org_timestamp ? client?.mua_org_timestamp : ''} GMT
                                    </div>
                                  </dd>
                                </div>

                              </dl>
                            </li>

                          );

                        }))}

                      </ul>
                    </Suspense>
                  </>
                ) : (
                  <div className="border rounded-md p-4 my-4 text-red-500 w-full flex flex-col text-center items-center justify-between">
                    <img src={empty} alt="empty" width="40%" className="text-center items-center justify-between" />
                    No Active Trades.
                  </div>

                )
                }

              </div>
            </div>

          ) : (

            <ConnectedAccLoader items={3} />

          )}

          {/* Last 60 minutes > && < 24 Hours data */}
          <div className="mx-auto max-w-7xl px-4 sm:px-4 lg:px-8 d-none hidden hide">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                <h2 className="text-base font-semibold leading-7 text-[#ff9747]">Last 24 Hours</h2>
                <a href="#" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  View all<span className="sr-only">, customers</span>
                </a>
              </div>
              <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                {currentConnection?.data?.map((client) => (
                  <li key={client.id} className="overflow-hidden rounded-xl border border-gray-200">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                      <img
                        src={client.imageUrl}
                        alt={client.name}
                        className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                      />
                      <div className="text-sm font-medium leading-6 text-gray-900">{client.name}</div>
                      <div as="div" className="relative ml-auto">
                        <div className="text-green-700 bg-green-50 ring-green-600/20 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset" >
                          Active
                        </div>
                      </div>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Account No.</dt>
                        <dd className="text-gray-700">
                          123456
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Balance</dt>
                        <dd className="flex items-start gap-x-2">
                          <div className="font-medium text-gray-900">$199.00</div>
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Last Active</dt>
                        <dd className="flex items-start gap-x-2">
                          <div className="font-medium text-gray-900">12:45 GMT</div>
                        </dd>
                      </div>
                    </dl>
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>) : null}

        <div>
          <Transition.Root show={modalOpenFlage} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={setmodalOpenFlage}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  {type == "Edit" ? "Update" : "Add"} Account
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md  text-cyan-200 hover:text-white"
                                    onClick={() => setmodalOpenFlage(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">


                                <div>
                                  <label
                                    htmlFor="user_name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    User Email
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="user_name"
                                    id="wp_user_id"
                                    // menuPlacement="top"
                                    placeholder={
                                      <span>
                                        Select User Email
                                        <span className="text-red-600">*</span>
                                      </span>
                                    }
                                    value={selectedUserId}
                                    onChange={(e) => {
                                      values.fk_wp_user_id = e.value;
                                      values.user_name = e.user_name;
                                      getAllProduct(e.value);
                                      getAllPreviousAccount(e.value);
                                      setSelectedUserId(e);
                                      getAllLicense(e?.value);
                                    }}
                                    components={animatedComponents}
                                    options={
                                      userList ? userList
                                        : []
                                    }
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />

                                  {errors.fk_wp_user_id && touched.fk_wp_user_id ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_wp_user_id.replace("fk_wp_user_id", "User")}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="user_name"
                                      className="block text-sm font-medium text-gray-900 mb-1"
                                    >
                                      User Name
                                      <span className="text-red-600">*</span>
                                    </label>
                                  </div>
                                  <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                    <input
                                      disabled={true}
                                      value={values.user_name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="User Name"
                                      name="user_name"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.user_name &&
                                      touched.user_name ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.user_name.replace("user_name", "User Name")}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="fk_wp_prod_id"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Select Product
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="prod_name"
                                    id="wp_prod_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span>
                                        Select Product
                                        <span className="text-red-600">*</span>
                                      </span>
                                    }
                                    value={selectedProductId}
                                    onChange={(e) => {
                                      setSelectedProductId(e);
                                      values.fk_wp_prod_id = e.value;
                                      getAllLicense(selectedUserId?.value);
                                      setSelectedLicId({ label: "", value: "" })
                                      setSelectedPreAccountId({ label: "", value: "" })
                                    }}
                                    components={animatedComponents}
                                    options={
                                      productList ? productList
                                        : []
                                    }
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />

                                  {errors.fk_wp_prod_id && touched.fk_wp_prod_id ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_wp_prod_id.replace("fk_wp_prod_id", "Product")}
                                    </div>
                                  ) : null}
                                </div>
                                <div>
                                  <label
                                    htmlFor="license_key"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Select License
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="license_key"
                                    id="wp_lic_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span>
                                        Select License
                                        <span className="text-red-600">*</span>
                                      </span>
                                    }
                                    value={selectedLicId}
                                    onChange={(e) => {
                                      values.fk_wp_lic_id = e.value;
                                      values.prod_name = e?.master_whop_product_relation?.prod_name;
                                      setSelectedLicId(e);
                                    }}
                                    components={animatedComponents}
                                    options={
                                      licenseList ? licenseList
                                        : []
                                    }
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />

                                  {errors.fk_wp_lic_id && touched.fk_wp_lic_id ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_wp_lic_id.replace("fk_wp_lic_id", "License")}
                                    </div>
                                  ) : null}
                                </div>

                                {type == "Edit" ? (<div>
                                  <label
                                    htmlFor="lam_account_no"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Select Previous Account
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>) : (<></>)}
                                {type == "Edit" ? (<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="lam_account_no"
                                    id="lam_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span>
                                        Select Previous Account
                                        <span className="text-red-600">*</span>
                                      </span>
                                    }
                                    value={selectedPreAccountId}
                                    onChange={(e) => {
                                      // console.log(e);
                                      values.fk_previous_account_id = e.value;
                                      values.lam_account_no = e.label;
                                      values.lam_account_no = type == "Edit" ? "" : values.lam_account_no;
                                      setSelectedPreAccountId(e);
                                    }}
                                    components={animatedComponents}
                                    options={
                                      preAccountList ? preAccountList
                                        : []
                                    }
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />

                                  {errors.fk_wp_lic_id && touched.fk_wp_lic_id ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_wp_lic_id.replace("fk_wp_lic_id", "License")}
                                    </div>
                                  ) : null}
                                </div>) : (<></>)}

                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">

                                  <div>
                                    <label
                                      htmlFor="lam_account_no"
                                      className="block text-sm font-medium text-gray-900 mb-1"
                                    >
                                      Account No
                                      <span className="text-red-600">*</span>
                                    </label>
                                  </div>
                                  <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.lam_account_no}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Account No"
                                      name="lam_account_no"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.lam_account_no &&
                                      touched.lam_account_no ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.lam_account_no.replace("lam_account_no", "Account No")}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div className="basis-1/2 text-red-500 text-sm">
                                    {errorMessage !== "" &&
                                      "Error: " + errorMessage}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              onClick={() => {
                                setmodalOpenFlage(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={disableSubmitButton}
                              type="submit"
                              className={`ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                                ? "bg-gray-700 hover:bg-gray-700"
                                : "bg-cyan-700"
                                }`}
                            >
                              {disableSubmitButton ? (
                                <FaSpinner color="white" />
                              ) : (
                                type == "Edit" ? "Update" : "Add"
                              )}
                            </button>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </main>)}
    </>
  )
}

export default Home