/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";

export default {

  async getAllGroup(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/group?search=${payload}`);
    return response;
  },

  async saveGroup(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/group`, payload);
    return response;
  },

  async getAllSingleGroup(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/group/${payload}`);
    return response;
  },
  async setGroupStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/group/status`, payload);
    return response;
  },
  async deleteGroup(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/group/remove`, payload);
    return response;
  },

  /* Parameter Config */

  async getAllPCGroup(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/param-group?search=${payload}`);
    return response;
  },

  async savePCGroup(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/param-group`, payload);
    return response;
  },
  
  async deletePCGroup(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/param-group/remove`, payload);
    return response;
  },

  /* Parameter Config V2 */

  async getAllPCGroupV2(payload) {
    const response = await Api().get(`api/dashboard/param-group-v2?search=${payload}`);
    return response;
  },

  async savePCGroupV2(payload) {
    const response = await Api().post(`api/dashboard/param-group-v2`, payload);
    return response;
  },
  
  async deletePCGroupV2(payload) {
    const response = await Api().post(`api/dashboard/param-group-v2/remove`, payload);
    return response;
  },

};
